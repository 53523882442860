import { lightGreen, green, red } from '../../constants/Colors';
import './Tees.css';

export default function Tees(props) {


  const renderTees = () => {

    const markDiv = {
      height:20,
      //width:33
    };

    let mark1 = "tee";
    let mark2 = "tee";
    let mark3 = "tee";

    if (props.tees == 1){
      mark1 = "teeOn";}

    if (props.tees == 2){
      mark2 = "teeOn";}

    if (props.tees == 3){
      mark3 = "teeOn";}

    return (

      <div className="teeRow">

        <div style={markDiv}>

          <div className={mark1}></div>

        </div>

        <div style={markDiv}>

          <div className={mark2}></div>

        </div>

        <div style={markDiv}>

          <div className={mark3}></div>

        </div>

      </div>


    );

  }


  return(

    <div style={{alignItems:'center', justifyContent:'center'}}>

      {renderTees()}

    </div>

  );



}
