import React from 'react';

export default function Stat(props){


  let statNum = 0;
  if (props.num){
    statNum = props.num;
  }

  return(

    <div style={{padding:'5px', alignItems:'center'}}>
      <div className="gameScore">{statNum.toLocaleString()}</div>
      <div className="description">{props.title}</div>
    </div>

  );

}
