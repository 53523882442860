import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import moment from 'moment';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './GameView.css';
import { API_URL } from '../../constants/Strings';

import BallPath from './BallPath';
import UserRow from './UserRow';
import GameCard from './GameCard';
import Putt from './Putt';
import Leaderboard from './Leaderboard';
import PuttrSpinner from '../common/PuttrSpinner';

export default function GameView() {

  const [gameDetails, setGameDetails] = useState(null);
  const [gamePutts, setGamePutts] = useState(null);
  const [highScore, setHighScore] = useState(0);
  const [gameVideo, setGameVideo] = useState(null);
  const [gameReplayVideo, setGameReplayVideo] = useState(null);
  const [replayLoading, setReplayLoading] = useState(false);
  let replayInterval;

  const { gameId } = useParams();

  useEffect(() => {
    getGameDetails();
    getGamePutts();
    getGameVideo();

    return () => {

    };
  }, []);

  const getGameDetails = () => {
    setGameDetails(null);

    const url = API_URL + "/club/analytics/game/" + gameId;

    axios.get(url)
      .then(res => {
        setGameDetails(res.data);
        //console.log("game data:", res.data);
        const id = res.data[0].game.gameId;
      })
  }
  const getGamePutts = () => {

    setGamePutts(null);

    const url = API_URL + "/club/analytics/putts/" + gameId;

    axios.get(url)
      .then(res => {
        setGamePutts(res.data);
      })
  }

  const getGameVideo = () => {
    const url = API_URL + '/video/recordings/' + gameId;
    axios.get(url)
      .then(res => {
        setGameVideo(res.data);
        getGameReplayVideo();
      });
  }
  const getGameReplayVideo = () => {

    const url = API_URL + '/video/replays/' + gameId;

    axios.get(url)
      .then(res => {

        setGameReplayVideo(res.data);

        if (res.data.length > 0){

          if (res.data[0].status == 1){
            setReplayLoading(false);
            clearInterval(replayInterval);
          }

        }

      });
  }

  /*
  const renderGameDetails = () => {

    if (gameDetails){

      const gd = gameDetails[0].game;
      const u = gameDetails[1].user;
      const m = moment(gd.created_at, "YYYY-MM-DD").fromNow();

      return(

        <div className="center align-items-center justify-content-center" style={{height:'150px'}}>

          <div className="gameIcon">

            <FontAwesomeIcon color="green" icon={gd.icon} />

          </div>

          <div className="title">{gd.title}</div>

          <div>{gd.players} player</div>

          <div className="timeAgo">{m}</div>

          <div style={{marginTop:'10px'}}>

            <UserRow user={u} />

          </div>

        </div>
      );
    }
  }
  */
  const renderGamePuttsOld = () => {

    if (gamePutts){

      return gamePutts.map((putt, index) => {

        let t = "LEFT";
        if (putt.tee == 2)
          t = "CENTER";
        else if (putt.tee == 3)
          t = "RIGHT";

        return (

          <div key={index} style={{alignItems:'center', justifyContent:'center'}}>

            <div style={{width:'320px', marginLeft:'5px', marginRight:'5px', alignItems:'center', justifyContent:'center'}}>

              <BallPath ballPath={putt.ballPath} putt={putt} width={300} />

            </div>

            <div className="puttLocation">

              <b>{putt.distance}</b>&nbsp;feet, <b>{t}</b>&nbsp;tee

            </div>

          </div>

        );

      });
    }
  }
  const renderGamePutts = () => {

    if (gamePutts){

      return gamePutts.map((putt, index) => {

        return (

          <div key={index} style={{alignItems:'center', justifyContent:'center'}}>

            <Putt putt={putt} index={index} />

          </div>

        );

      });
    }
  }
  const renderGameCard = () => {

    if (gameDetails && gamePutts){

      return(
        <GameCard
          leaderboard={gameDetails[0].game}
          user={gameDetails[1].user}
          putts={gamePutts}
        />
      );

    }

    return(
      <div style={{height:'500px'}} className="align-items-center justify-content-center">

        <h3>LOADING GAME DETAILS</h3>

      </div>
    );

  }
  const renderGameLeaderboard = () => {

    if (gameDetails){

      const gd = gameDetails[0].game;

      return(

        <Leaderboard clubId={gd.clubId} gameId={gd.gameId} setHighScore={setHighScore} />

      );
    }


  }
  const createReplayVideo = () => {
    console.log("creating replay:");

    setReplayLoading(true);
    let url = API_URL + "/video/createVideoClip";
    const params = {
      userGameId: gameId,
    };

    axios.post(url, params)
      .then(res => {
        replayInterval = setInterval(getGameReplayVideo,5000);
      })
  }
  const renderVideoInfo = () => {

    if (gameVideo){//if there is a recording

      if (gameVideo.length > 0){//if there is a recording

        if (gameReplayVideo){//if there is a replay

          if (gameReplayVideo.length > 0) {//there is a video in process or complete

            if (gameReplayVideo[0].status == 1){
              return(
                <a href={gameReplayVideo[0].videoUrl} target="_blank" className="btn">WATCH REPLAY</a>

              );
            }
            else {
              return <Spinner animation="grow" />;
            }

          }

          else {

            if (replayLoading){
              return <Spinner animation="grow" />;
            }

            return(

              <div style={{padding:10}}>

                <Button variant="success" onClick={createReplayVideo}>CREATE REPLAY</Button>

              </div>
            );

          }
        }

        if (!replayLoading){
          return(
            <div className="button" onClick={createReplayVideo}>CREATE REPLAY</div>
          );
        }
        else {
          return <Spinner animation="grow" />;
        }


      }

    }
    else {
      console.log("no video recording");
    }
  }
  const renderPage = () => {

    if (gamePutts && gameDetails){

      return(

        <div style={{display: 'grid', placeItems: 'center'}}>

          <div style={{marginTop:'10px'}} className="prow">

            <div className="center" style={{marginTop:'20px'}}>

              {renderGameCard()}

            </div>

          </div>

          <div style={{padding:5}} className="prow">

            {renderVideoInfo()}

          </div>

          <h3>PUTTS</h3>

          <div style={{marginTop:10}} className="prow">

            {renderGamePutts() }

          </div>

          <div style={{marginTop:'20px'}} className="prow">

            <h3>LEADERBOARD</h3>

          </div>

          <div style={{marginTop:'20px', alignItems:'center'}}>

            <Card style={{padding:10}}>

              {renderGameLeaderboard() }

            </Card>

          </div>

        </div>

      );

    }

    else {

      return(



        <PuttrSpinner />


      );
    }

  }

  return(

    <Container fluid className="align-items-center justify-content-center">

      {renderPage()}

    </Container>

  );


}
