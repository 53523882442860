import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import NavBar from './navigation/NavBar';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import { API_URL } from '../constants/Strings';
import { green, lightGreen, red } from '../constants/Colors';
import SideBar from './navigation/SideBar';
import PuttrSpinner from './common/PuttrSpinner';
import Stat from './common/Stat';
import { getToken } from './common/GetToken';
import NavProfile from './navigation/NavProfile';
import CreateAccount from './profile/CreateAccount';


function ProfileScreen(props){

  const [userStats, setUserStats] = useState(null);

  useEffect(() => {

    getData();

  },[]);
  const getData = async() => {

    if (props.app.profile){

      const tok = await getToken();
      let url = API_URL + '/profile/stats/30';
      const opts = { headers: { Authorization: `Bearer ${tok}`} };

    	axios.get(url, opts)
      .then(request => {
        setUserStats(request.data[0]);
        console.log("stats:", request.data);
      })
      .catch(function (error) {
        console.log("Fetch error:", error);
      });
    }
  }
  const renderMembership = () => {

    if (props.app.profile.isMember){
      return(
        <div style={{marginTop:'20px', alignItems:'center'}} className="prow">

          <h4>PUTTR CLUB MEMBER</h4>

          <FontAwesomeIcon icon={faCircleCheck} color={lightGreen} size="2x" />

        </div>

      );
    }

    else if (props.app.profile.isClubAdmin){
      return(
        <div style={{marginTop:'20px', justifyContent:'center', alignItems:'center'}} >

          <h3>YOU ARE A CLUB ADMIN AT <b>{props.app.profile.isClubAdmin}</b></h3>

          <div style={{textAlign:'center'}}><Link to="/dashboard" className="link">GO TO DASHBOARD</Link></div>

        </div>
      );
    }

    else {
      return(
        <div style={{display: 'grid', placeItems: 'center'}}>
          <FontAwesomeIcon icon={faCircleXmark} color={red} size="2x" />
          <div className="label" style={{marginTop:10}}>SIGN UP IN PUTTR APP</div>
        </div>
      );
    }
  }

  const renderClubAdmin = () => {
    if (props.app.profile.isClubAdmin){
      return(
        <FontAwesomeIcon icon={faCircleCheck} color={lightGreen} size="2x" />
      );
    }

  }
  const renderStripeAccount = () => {
    return(
      <CreateAccount />
    );
  }
  const renderHome = () => {

    if (props.app.profile && userStats){

      return(

        <div style={{ display: 'flex', height: '100%' }}>

          <Container fluid className="align-items-center justify-content-center">

            <div style={{marginTop:20, display: 'grid', placeItems: 'center'}}>

              <NavProfile user={props.app.profile} />

            </div>

            <div style={{marginTop:'20px', alignItems:'center'}} className="prow">

              <h4>LAST 30 DAYS</h4>

            </div>

            <div style={{ alignItems:'center'}} className="prow">

              <Stat title="GAMES" num={userStats.games.total} />

              <Stat title="PUTTS" num={userStats.putts.total} />

              <Stat title="MADE" num={userStats.putts.made} />

              <Stat title="FEET" num={userStats.putts.totalFeet} />

            </div>


            <div style={{ alignItems:'center'}} className="prow">

              {renderMembership()}

            </div>

          </Container>

        </div>

      );
    }

    else {
      return(
        <PuttrSpinner />
      );
    }

  }
  return(

    <Container fluid className="align-items-center justify-content-center">

      <NavBar />

      {renderHome()}

      <SideBar />

    </Container>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, null)(ProfileScreen);
