const INITIAL_STATE = {
  clubId: null,
  user: null,
  profile:null,
  clubInfo:null,
  clubStats:null,
  clubPutts:null
}

export default (state = INITIAL_STATE, action) => {

 switch (action.type) {

  case 'SET_CLUB_ID':
    return { ...state, clubId: action.payload };
  case 'SET_USER':
    return { ...state, user: action.payload };

  case 'PROFILE_RECEIVED':
    return { ...state, profile: action.payload };

  case 'CLUB_INFO_RECEIVED':
    return { ...state, clubInfo: action.payload };

  case 'CLUB_STATS_RECEIVED':
    return { ...state, clubStats: action.payload };

  case 'CLUB_PUTTS_RECEIVED':
    return { ...state, clubPutts: action.payload };

  default:
   return state

 }
}
