import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export const getToken = async () => {
  const tok = await firebase.auth().currentUser.getIdToken();
  //console.debug("tok:" , tok);
  return new Promise((resolve, reject) => {
    if (tok) {
      resolve(tok);
    } else {
      reject('Error');
    }
  })

}
