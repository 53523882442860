import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { createBrowserRouter, RouterProvider, Navigate, useNavigate } from "react-router-dom";
import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { config } from '../../constants/firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Container from 'react-bootstrap/Container';

import ErrorPage from "../ErrorPage";
import SignIn from '../navigation/SignIn';
import HomeScreen from '../HomeScreen';
import DashboardScreen from '../DashboardScreen';
import BrandingScreen from '../BrandingScreen';
import UsersScreen from '../UsersScreen';
import ProfileScreen from '../ProfileScreen';
import GuideScreen from '../GuideScreen';
import SubscriptionScreen from '../SubscriptionScreen';

import ClubLeaderboard from '../club/ClubLeaderboard';
import GameView from '../games/GameView';
import PuttrSpinner from '../common/PuttrSpinner';

import { setUser, getProfile } from '../../actions/AppActions';
import { getToken } from '../common/GetToken';

firebase.initializeApp(config);

function Navigator(props) {

  const [isLoadingUser, setIsLoadingUser] = useState(true);

  useEffect(() => {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        props.setUser(user);
        getData();
      }
      else {
        props.setUser(null);
      }
      setIsLoadingUser(false);
    });

  },[]);

  const getData = async () => {
    const tok = await getToken();
    props.getProfile(tok);
  }

  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/signin" replace />;
    }
    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/signin",
      element: <SignIn />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/profile",
      element: <ProtectedRoute user={props.app.user}><ProfileScreen /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/dashboard",
      element: <ProtectedRoute user={props.app.user}><DashboardScreen /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/branding",
      element: <ProtectedRoute user={props.app.user}><BrandingScreen /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/users",
      element: <ProtectedRoute user={props.app.user}><UsersScreen /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/guide",
      element: <GuideScreen />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/subscription",
      element: <ProtectedRoute user={props.app.user}><SubscriptionScreen /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "/leaderboard/:clubId",
      element: <ClubLeaderboard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/game/:gameId",
      element: <GameView />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/",
      element: <ProtectedRoute user={props.app.user}><DashboardScreen /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
  ]);


  if (isLoadingUser){
    return(

      <Container fluid className="align-items-center justify-content-center">

        <PuttrSpinner />

      </Container>

    );
  }

  return(

    <RouterProvider router={router} />

  );

}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, {setUser, getProfile})(Navigator);
