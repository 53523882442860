import React, {useState} from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFile, faChartLine, faCog, faSignOutAlt, faCopyright, faUserGroup, faUser, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { getAuth, signOut } from "firebase/auth";

import { lightGreen } from '../../constants/Colors';
import NavProfile from './NavProfile';

function SideNav(props) {

  const [rtl, setRtl] = useState(false);
  const [broken, setBroken] = useState(false);
  const [toggled, setToggled] = useState(false);
  let location = useLocation();

  const logoutUser = () => {

    const auth = getAuth();
    signOut(auth).then(() => {
      console.log("signed out");
    }).catch((error) => {

    });

  }
  const renderToggle = () => {

    if (broken){
      return(
        <div style={{position:'absolute', top:25, left:20}} onClick={() => {setToggled(true)}}>

          <FontAwesomeIcon icon={faBars} color='#666' size="lg" />

        </div>
      );
    }

  }

  const renderMenuItem = (title, path, icon) => {

    const p = location.pathname;
    let color = '#333';
    let fontStyle = { fontFamily:'Montserrat', color:color};

    if (p == path){
      color = lightGreen;
      fontStyle = { fontFamily:'Montserrat', fontWeight:'bold', color:color};
    }

    return(
      <MenuItem
        icon={<FontAwesomeIcon icon={icon} color={color} size="lg" />}
        component={<Link to={path} />}
        rootStyles={fontStyle}
      >
        {title}
      </MenuItem>
    );
  }

  const renderClubMenu = () => {
    if (props.app.profile.isClubAdmin){

      //{renderMenuItem('SUBSCRIPTION', '/subscription', faFile)}
      return(
        <div>

          {renderMenuItem('BRANDING', '/branding', faCopyright)}
          {renderMenuItem('USERS', '/users', faUserGroup)}

        </div>
      );
    }
  }

  if (props.app.profile){

    //{renderMenuItem('PROFILE', '/profile', faUser)}

    return (

      <div style={{ position:'absolute', top:0, left:0, display: 'flex', height: '100%', direction: rtl ? 'rtl' : 'ltr' }}>

        <Sidebar
          onBreakPoint={setBroken}
          rtl={rtl}
          breakPoint="lg"
          toggled={toggled}
          onBackdropClick={() => setToggled(false)}
          backgroundColor="rgb(249, 249, 249, 0.9)"
        >

          <div style={{ display: 'flex', flexDirection: 'column',  marginTop:30 }}>

            <div style={{alignItems:'center', marginBottom:20}}>
              <NavProfile user={props.app.profile} />
            </div>

            <Menu>

              {renderMenuItem('DASHBOARD', '/dashboard', faChartLine)}
              {renderMenuItem('HOW TO', '/guide', faCircleQuestion)}

              {renderClubMenu()}

              {renderMenuItem('SUBSCRIPTION', '/subscription', faFile)}

              <MenuItem
                icon={<FontAwesomeIcon icon={faSignOutAlt} color='#666' size="lg" />}
                onClick={logoutUser}
                rootStyles={{fontFamily:'Montserrat'}}
              >
                LOGOUT
              </MenuItem>
            </Menu>
          </div>
        </Sidebar>

        {renderToggle()}

      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, null)(SideNav);

//export default NavBar;
