import React from 'react';
import Image from 'react-bootstrap/Image';

export default function NavProfile(props){

  const renderAvatar = () => {

    if (props.user.photoURL){
      return(
        <div className="avatar">
          <Image roundedCircle src={props.user.photoURL} width="50" height="50" alt="Photo" />
        </div>
      );
    }

    else {
      const i = props.user.displayName.substring(0,1);
      return(
        <div className="avatar"> {i} </div>
      );

    }
  }

  const u = props.user;

  return(

    <div style={{display: 'grid', placeItems: 'center'}}>

      {renderAvatar()}

      <div style={{ marginTop:10, display: 'grid', placeItems: 'center'}} >

        <div className="displayName">{u.displayName}</div>

        <div className="hometown">{u.homeTown}</div>

      </div>

    </div>

  );

}
