import React from 'react';
import Container from 'react-bootstrap/Container';
import logo from '../../images/logo_only.svg';
import './Spinner.css';

export default function PuttrSpinner(props){

  return(

    <div style={{display: 'grid', placeItems: 'center', height: '100vh', width:'100%'}}>

      <div>

        <img src={logo} className="App-logo" alt="logo" />

        <div className="label">LINING IT UP...</div>

      </div>

    </div>

  );
}
