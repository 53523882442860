import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";

import logo from '../../images/logo_dark.svg';

function NavBar(props){

  const logoutUser = () => {
    console.log("logging out");

    const auth = getAuth();
    signOut(auth).then(() => {
      console.log("signed out");
    }).catch((error) => {
      // An error happened.
    });

    /*

    firebase.auth().signOut().then(function() {
      console.log("signed out");
    }).catch(function(error) {

    });
    */
  }

  const renderLinks = () => {

    if (props.app.profile){
      return(
        <React.Fragment>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="/dashboard">DASHBOARD</Nav.Link>
            <NavDropdown title={props.app.profile.displayName} id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={logoutUser}>
                LOGOUT
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        </React.Fragment>
      );
    }
  }

  return(

    <Navbar expand="lg" className="navbar">
      <Container>
        <Navbar.Brand className="brand"><Link to="/"><img src={logo} width="150" height="50" alt="logo" /></Link></Navbar.Brand>

      </Container>
    </Navbar>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, null)(NavBar);
