import './App.css';
import { store } from './reducers/store';
import { Provider } from 'react-redux';

import Navigator from './components/navigation/Navigator';

function App() {
  
  return (

    <Provider store={store}>

      <Navigator />

    </Provider>
  );
}

export default App;
