import { useRouteError, Link } from "react-router-dom";

import NavBar from './navigation/NavBar';
import Container from 'react-bootstrap/Container';

export default function ErrorPage() {

  const error = useRouteError();
  console.error(error);

  return (

    <Container fluid className="align-items-center justify-content-center">

      <NavBar />

      <div style={{marginTop:30, display: 'grid', placeItems: 'center'}}>

        <div style={{textAlign:'center'}}>

          <h1>WOOPS, LOOKS LIKE WE MISSED THAT ONE</h1>

          <h3>This page had an error, which is kind of like a 3 putt...</h3>

          <Link to="/" className="link">GO HOME</Link>

          </div>

      </div>

    </Container>

  );
}
