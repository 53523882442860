export const getTeeEntry = (distance, tee) => {

  let perfAng = 0;
  let a = 0;
  if (tee == 1){
    a = -8;
  }
  else if (tee == 3){
    a = 8;
  }
  const b = distance * 12;
  const perfRad = Math.atan(a/b);
  perfAng = Math.round(perfRad * (180 / Math.PI));
  return perfAng;
}

export const getHoleEntry = (ballPath, holeBounds) => {

  const holeCenterX = holeBounds[0];
  const holeCenterY = holeBounds[1];
  const holeRadius = holeBounds[2] / 2;
  //let holeMade = false;

  for (let i = 0; i < ballPath.length; i++) {
    let ballX = ballPath[i][0];
    let ballY = ballPath[i][1];

    //get the first point that is within the radius of hole (60 pixels)
    const a = holeCenterX - ballX;// a
    const b = holeCenterY - ballY;// b

    const rad = Math.sqrt( (a*a) + (b*b) );

    if (rad < holeRadius){//this is the first within the radius of the hole
      //see which quadrant of hole
      let xQuad = "RIGHT";
      let yQuad = "FRONT"
      if (ballX < holeCenterX)
        xQuad = "LEFT";
      if (ballY < holeCenterY)
        yQuad = "BACK";

      const quad = xQuad + " " + yQuad;
      const rad = Math.atan(a/b);//calculate degress from center
      let deg = Math.round(rad * (180 / Math.PI));

      if (quad == "LEFT BACK"){ //ball entered into back half of hole
        //negative degrees
        deg = -90 - (90 - deg);
      }
      else if (quad == "RIGHT BACK"){ //ball entered into back half of hole
        //positive degrees -25
        deg = 90 + (90 + deg);
      }

      return [ballX, ballY, deg, quad];
    }
  }
  //if not entry point found, return 0
  return [0,0,0,0];
}
