import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';

import AppReducer from './AppReducer';

const rootReducer = combineReducers({
	app: AppReducer
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
