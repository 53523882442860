import React from 'react';
import { connect } from 'react-redux';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { green } from '../constants/Colors';
import NavBar from './navigation/NavBar';
import Container from 'react-bootstrap/Container';

import Spinner from 'react-bootstrap/Spinner';

import SignIn from './navigation/SignIn';
import SideBar from './navigation/SideBar';

function HomeScreen(props){

  const renderHome = () => {

    if (props.app.profile){

      return(

        <div style={{ display: 'flex', height: '100%' }}>

          <Container fluid className="align-items-center justify-content-center">

            <div style={{textAlign:'center'}}>YO</div>

          </Container>

          <SideBar />

        </div>

      );
    }

    else {

      return(
        <Spinner animation="border" variant="success" />
      );

    }

  }



  return(

    <Container fluid className="align-items-center justify-content-center">

      <NavBar />

      {renderHome()}

    </Container>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, null)(HomeScreen);
