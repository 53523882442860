import React from 'react';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { lightGreen } from '../../constants/Colors';
import './ClubLeaderboard.css';

export default function LeaderboardCard(props) {

  const lb = props.leaderboard.leaderboard;
  const sc = props.leaderboard.scores;

  const getPlaceText = (place) => {
    let str = place;
    if (place == 1)
      str += "st";
    if (place == 2)
      str += "nd";
    if (place == 3)
      str += "rd";
    else if (place > 3)
      str += "th";
    return str;
  }
  const renderAvatar = (user) => {
    if (user.photoURL){
      return(
        <div className="avatar">
          <Image roundedCircle src={user.photoURL} width="40" height="40" alt="Photo" />
        </div>
      );
    }

    else {
      const i = user.displayName.substring(0,1);
      return(
        <div className="avatar"> {i} </div>
      );

    }
  }
  const renderScores = (scores) => {

    if (scores.length == 0)
      return <div className="title">NO SCORES YET</div>

    let ct = 0;
    let howMany = 5;
    if (props.fullBoard)
      howMany = 1000;

    let dot = {
      position: 'relative',
      borderColor:'#fff',
      borderStyle:'solid',
      color:'#fff',
      borderWidth: 1,
      left:10,
      top:-15,
      width:25,
      height:25,
      borderRadius:12.5,
      backgroundColor:lightGreen,
      alignItems:'center',
      justifyContent:'center',
      fontWeight:'bold',
      fontSize:'10pt',
      textAlign:'center'
    };

    return scores.map((score, index) => {

      const gameLink = "/game/" + score.userGameId;

      if (ct++ < howMany){

        const user = JSON.parse(score.userName);
        //const pl = getPlaceText(index+1);

        return(

          <div key={index} className="lbRow">

            <div style={dot}>{index+1}</div>

            {renderAvatar(user)}

            <div className="lbDisplayName" style={{ marginLeft:5, width:190}}>{user.displayName}</div>

            <Link className="link" to={gameLink}>

              <div className="lbScore">{score.score}</div>

            </Link>

          </div>

        );

      }

    });
  }

  const renderBackLink = () => {

    if ((props.fullBoard) && (!props.signage) ) {
      return(

        <div style={{padding:10}}>

          <Link to="#" className="link" onClick={() => {props.selectLeaderboard(null)}}>

            GO BACK

          </Link>

        </div>
      );
    }

  }
  const renderMoreLink = (lb) => {

    if ( (props.signage != 1) && (!props.fullBoard) ) {

      const ll = "/leaderboard/" + props.clubId + "/" + lb.id;

      return (

        <div style={{padding:10}}>

          <Link to="#" className="link" onClick={() => {props.selectLeaderboard(props.leaderboard)}}>

            VIEW FULL LEADERBOARD >

          </Link>

        </div>

      );
    }


  }

  return(

    <Card key={lb.id} className="lbCard">

      <div style={{display: 'grid', placeItems: 'center', marginTop:10}}>

        <div style={{padding:10}}>

          <div className="lbTitle">{lb.title}</div>

        </div>

        {renderBackLink()}

        <div className="justify-content-start">

          {renderScores(sc)}

        </div>

        {renderMoreLink(lb)}

        {renderBackLink()}

      </div>

    </Card>


  );

}
