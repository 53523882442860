import React from 'react';
import Row from 'react-bootstrap/Row';

let mult = 1;//multiplier for ratio of green view
let xAdjust = 82.5;

export default function BallPath(props) {

  let w = 475;
  if (props.width){
    w = props.width;
  }

  mult = w / 475;
  xAdjust = 82.5 * mult;

  const renderPoints = (xHoleDiff) => {

    var url_string = window.location.href;
    var url = new URL(url_string);
    var s = url.searchParams.get("showRad");

    if (props.showRad){
      s = 1;
    }

    if (props.putt.ballPath){

      var bp = JSON.parse(props.putt.ballPath);

      return bp.map((item, index) => {
        const x = ((item[0] + xHoleDiff) * mult) - xAdjust;
        const y = item[1] * mult;

        let lastX = x;
        let lastY = y;
        let ballSize = 20 * mult;

        if (s){
          ballSize = item[2] * mult;
        }

        return(

          <circle cx={x} cy={y} r={ballSize} fillOpacity='.9' fill='#fff' stroke='#fff' key={index} />

        );

      });
    }
  }

  const renderBallPath = () => {

    const stroke = "rgb(255,255,255)";

    const gw = 475 * mult;
    const gh = 350 * mult;

    let realHoleX = 320;
    let realHoleY = 100;
    let realHoleRad = 60;
    let xHoleDiff = 0;


    if (props.putt.holeBounds){
      let hb = JSON.parse(props.putt.holeBounds);
      realHoleX = hb[0];
      xHoleDiff = 320 - realHoleX;
      realHoleX += xHoleDiff;
      realHoleY = hb[1];
      realHoleRad = hb[2] / 2;
    }

    let holeX = (realHoleX * mult) - xAdjust;
    let holeY = realHoleY * mult;
    let holeRad = realHoleRad * mult;

    return(

      <div>

      <svg height={gh} width={gw}>

        <circle cx={holeX} cy={holeY} r={holeRad} fill='#111' stroke={stroke} />

        {renderPoints(xHoleDiff)}

      </svg>

      </div>

    );

  }
  return(

    <div style={{alignItems:'center'}}>

      { renderBallPath() }

    </div>

  );



}
