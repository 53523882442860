import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { getAuth, signInWithEmailAndPassword,getAdditionalUserInfo, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { redirect, useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { API_URL } from '../../constants/Strings';
import { getProfile } from '../../actions/AppActions';
import { getToken } from '../common/GetToken';
import NavBar from '../navigation/NavBar';

const provider = new GoogleAuthProvider();

function SignIn(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [signinError, setSigninError] = useState(null);
  const [currentState, setCurrentState] = useState("SIGNIN");//default to sign in
  const navigate = useNavigate();

  const signInUser = () => {

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        document.location = '/';
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setSigninError(errorMessage);
      });
  }
  const signInGoogleUser = () => {
    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {

        console.log("signedin ", result);

        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;

        const { isNewUser } = getAdditionalUserInfo(result);


        console.log("isnewuser:", isNewUser);
        //check if new user and create profile
        if (isNewUser){
          createPuttrProfile(user);
        }
        else {
          document.location = '/';
        }
        //document.location = '/';
        //navigate('/dashboard')

      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        setSigninError(errorMessage);
      });

  }
  const signUpUser = async() => {

    if ( (password != '') && (email != '') && (passwordConfirm != '') ){

      if (password === passwordConfirm){

        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            createPuttrProfile(user);
            document.location = '/';
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setSigninError(errorMessage);
          });
      }
      else {
        setSigninError("Passwords must match");
      }

    }

    else {
      setSigninError("Must fill in all fields");
    }


  }
  const createPuttrProfile = async (user) => {

    const url = API_URL + '/profile/createSocial';
    const params = {
      userId: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      accountType: user.providerId,
      source: 'WEB'
    };

    axios.post(url, params)
    .then(function (response) {
      console.log("created profile");
      //navigate('/')
      setTimeout(() => {document.location = '/'}, 1000);
    })
    .catch(function (error) {
      console.log(error);
    });


  }
  const renderError = () => {
    if (signinError){
      return(
        <div style={{marginTop:10}}>
          <Alert variant="danger">{signinError}</Alert>
        </div>
      );
    }
  }
  const renderGoogle = () => {
    return(

      <div style={{marginTop:10}}>

        <Button variant="dark" onClick={signInGoogleUser}><FontAwesomeIcon icon={faGoogle} color='#ccc' size="sm" /></Button>

      </div>
    );
  }
  const renderForm = () => {

    if (currentState == "SIGNIN"){
      return(

        <div>

        <Card.Text>
          Sign in with your Puttr account
        </Card.Text>

        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>EMAIL</Form.Label>
            <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />

          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>PASSWORD</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
          </Form.Group>

          <Button variant="success" onClick={signInUser}>SIGN IN</Button>

          {renderGoogle()}

          <div style={{marginTop:10}}>

            <a className="link" onClick={() => {setCurrentState("SIGNUP")}}>CREATE ACCOUNT</a>

          </div>

          {renderError()}

        </Form>

        </div>
      );
    }

    else {//signup

      return(

        <div>

        <Card.Text>
          Sign up for a new Puttr account
        </Card.Text>

        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>EMAIL</Form.Label>
            <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />

          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>PASSWORD</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>CONFIRM PASSWORD</Form.Label>
            <Form.Control type="password" placeholder="Confirm Password" onChange={e => setPasswordConfirm(e.target.value)} />
          </Form.Group>

          <Button variant="success" onClick={signUpUser}>SIGN UP</Button>

          {renderGoogle()}

          <div style={{marginTop:10}}>

            <a className="link" onClick={() => {setCurrentState("SIGNIN")}}>SIGN IN</a>

          </div>

          {renderError()}

        </Form>

        </div>
      );

    }
  }
  return(

    <Container fluid className="align-items-center justify-content-center">

      <NavBar />

      <div style={{display: 'grid', placeItems: 'center'}}>

      <Card style={{ width: 300, marginTop:20, textAlign:'center'}}>

        <Card.Body>

          {renderForm()}

        </Card.Body>
      </Card>

      </div>

    </Container>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, {getProfile})(SignIn);
