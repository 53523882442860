export const config = {
  apiKey: "AIzaSyA2ZHh0-w8Luk6J8dZzqTaFt6WBW57Ycj4",
  authDomain: "puttr-287402.firebaseapp.com",
  databaseURL: "https://puttr-287402.firebaseio.com",
  projectId: "puttr-287402",
  storageBucket: "puttr-287402.appspot.com",
  messagingSenderId: "636312820806",
  appId: "1:636312820806:web:32254c04cf434d78a0fe47",
  measurementId: "G-K549BPJVQK"
};
