import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import './Subscription.css';

import SideBar from './navigation/SideBar';
import PuttrSpinner from './common/PuttrSpinner';
import NavBar from './navigation/NavBar';
import { getToken } from './common/GetToken';
import { API_URL } from '../constants/Strings';
import { green } from '../constants/Colors';
import { getProfile } from '../actions/AppActions';

function SubscriptionScreen(props){

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [stripeCustomer, setStripeCustomer] = useState(null);
  let [products, setProducts] = useState(null);

  useEffect(() => {

    getStripeUser();
    getStripeProducts();

  },[]);
  useEffect(() => {

    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setMessage("Your subscription has been succesfully processed. Our team will reach out when your club has been set up.");
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, []);
  const getStripeProducts = async () => {

    const tok = await getToken();
    let url = API_URL + '/web/getStripeProducts';
    const opts = { headers: { Authorization: `Bearer ${tok}`} };

    axios.get(url, opts)
    .then(request => {
      const prods = request.data;
      prods.data.sort(function(a, b){
        return a.default_price.unit_amount - b.default_price.unit_amount}
      );
      setProducts(prods);
    })
    .catch(function (error) {
      console.log("Create stripe user error:", error);
    });

  }
  const getStripeUser = async () => {

    const tok = await getToken();
    let url = API_URL + '/web/getStripeCustomer';
    const opts = { headers: { Authorization: `Bearer ${tok}`} };

    axios.get(url, opts)
    .then(request => {
      setStripeCustomer(request.data);
    })
    .catch(function (error) {
      console.log("Create stripe user error:", error);
    });

  }

  const renderMessage = () => {
    if (message){
      return(
        <section>
          <p>{message}</p>
        </section>
      );
    }

  }
  const renderSubscriptions = () => {

    if (stripeCustomer){

      const subNum = stripeCustomer.data.length;

      return (
        <div>
          <h3>You currently have <b>{subNum}</b> active subscriptions</h3>
          <form action={API_URL + "/web/createPortal"} method="POST">
            <input type="hidden" name="customer_id" value={props.app.profile.stripeCustId} />
            <Button type="submit" variant="secondary">MANAGE SUBSCRIPTIONS</Button>
          </form>
        </div>
      );

    }
    else {
      return(
        <div><h3>LOADING CUSTOMER INFORMATION</h3></div>
      );
    }

  }
  const renderMarketingFeatures = (features) => {

    return features.map((feature, index) => {
      return(
        <ListGroup.Item>{feature.name}</ListGroup.Item>
      );
    });


  }
  const renderProducts = () => {

    if (products){

      return products.data.map((product, index) => {

        const price = (product.default_price.unit_amount / 100).toLocaleString();

        return(

          <Card key={index} style={{ width: '18rem', marginLeft:10, marginRight:10, marginBottom:10 }}>

            <Card.Img variant="top" width="280" height="280" src={product.images[0]} />
            <Card.Body>
              <Card.Title>${price} / year</Card.Title>

              <ListGroup variant="flush">

                {renderMarketingFeatures(product.marketing_features)}

              </ListGroup>

              <form action={API_URL + "/web/createCheckout"} method="POST">
                <input type="hidden" name="price_id" value={product.default_price.id} />
                <input type="hidden" name="customer_id" value={props.app.profile.stripeCustId} />
                <Button type="submit" variant="primary">SUSBSCRIBE</Button>
              </form>

            </Card.Body>

          </Card>

        );

      });

    }

    else {

      return(
        <div><h3>LOADING PRODUCT INFORMATION</h3></div>
      );

    }

  }
  const renderHome = () => {

    if (props.app.profile){

      return(

        <div style={{ display: 'flex', height: '100%' }}>

          <Container fluid className="align-items-center justify-content-center">

            <div style={{marginTop:20, display: 'grid', placeItems: 'center'}}>

              <h1>SUBSCRIPTION</h1>

              {renderSubscriptions()}

              <h3 style={{marginTop:20}}>PRODUCTS</h3>

              <div className="prow" style={{alignItems:'start'}}>

                {renderProducts()}

              </div>

            </div>

          </Container>

          <SideBar />

        </div>

      );
    }

    else {
      return(
        <PuttrSpinner />
      );
    }

  }
  return(

    <Container fluid className="align-items-center justify-content-center">

      <NavBar />

      {renderHome()}

      <SideBar />

    </Container>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, { getProfile })(SubscriptionScreen);
