import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import NavBar from './navigation/NavBar';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import { Link } from "react-router-dom";

import SideBar from './navigation/SideBar';
import PuttrSpinner from './common/PuttrSpinner';
import { API_URL } from '../constants/Strings';
import { green } from '../constants/Colors';

function BrandingScreen(props){

  const [clubInfo, setClubInfo] = useState(null);
  const [clubName, setClubName] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [color, setColor] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [infoSaved, setInfoSaved] = useState(false);

  useEffect(() => {

    if (props.app.clubId){
      //console.log("CLUB ID:", props.app.clubId);
      getClubInfo(props.app.clubId);
    }

  },[]);

  const createClub = () => {
    console.log("creating club");
    //see if club already exists

  }
  const getClubInfo = (clubId) => {
    const url = API_URL + '/club/info/' + clubId;

  	axios.get(url)
    .then(request => {
      setClubInfo(request.data[0]);
      setClubName(request.data[0].clubName);
      setWelcomeMessage(request.data[0].welcomeMessage);
      setColor(request.data[0].headerColor)
    })
    .catch(function (error) {
      console.log("Fetch profile error:", error);
    });
  }
  const saveLogoFile = (e) => {

    e.preventDefault();

    if(e.target.files){
      const file = e.target.files[0];

      const url = API_URL + '/admin/club/saveLogo';
      const data = new FormData();
      const rand = Math.floor(Math.random() * 10000);
      let ext = ".jpg";

      if (file.type == "image/png")
        ext = ".png";
      else if (file.type == "image/jpeg")
        ext = ".jpg";
      else {
        console.log("not png or jpg");
        return;
      }

      const pName = clubInfo.clubId + "_header_" + rand  + ext;

      data.append("file", file);
      data.append("clubId", clubInfo.clubId);
      data.append("newName", pName);

      fetch(url, {
        method: 'post',
        body: data
      }).then(response => {
          console.log("image uploaded", response);
          getClubInfo(clubInfo.clubId);

        }).catch(err => {
          console.log(err)
        });

    }

  }
  const saveBackgroundFile = (e) => {

    e.preventDefault();

    if(e.target.files){
      const file = e.target.files[0];

      const url = API_URL + '/admin/club/saveBackground';
      const data = new FormData();
      const rand = Math.floor(Math.random() * 10000);
      let ext = ".jpg";

      if (file.type == "image/png")
        ext = ".png";
      else if (file.type == "image/jpeg")
        ext = ".jpg";
      else {
        console.log("not png or jpg");
        return;
      }

      const pName = clubInfo.clubId + "_bg_" + rand  + ext;

      data.append("file", file);
      data.append("clubId", clubInfo.clubId);
      data.append("newName", pName);

      fetch(url, {
        method: 'post',
        body: data
      }).then(response => {
          console.log("image uploaded", response);
          getClubInfo(clubInfo.clubId);

        }).catch(err => {
          console.log(err)
        });

    }

  }
  const saveInfo = () => {
    setIsLoading(true);

    console.log("new club name:" , color)

    const url = API_URL + '/admin/club/saveInfo';
    const params = {
      clubId: clubInfo.clubId,
      clubName: clubName,
      welcomeMessage: welcomeMessage,
      headerColor: color
    };
    axios.post(url, params)
    .then(function (response) {
      setIsLoading(false);
      setInfoSaved(true);
      getClubInfo(clubInfo.clubId);
    })
    .catch(function (error) {
      console.log(error);
    });

  }
  const renderButton = () => {
    if (isLoading){
      return(

        <div style={{width:'100px', alignItems:'center'}}>
          <Spinner animation="border" variant="success" />
        </div>
      );
    }

    else {
      return(
        <Button variant="success" onClick={saveInfo}>SAVE</Button>
      );
    }
  }
  const renderSaveAlert = () => {
    if (infoSaved){
      return(
        <Alert variant="success">INFO SAVED SUCCESFULLY!</Alert>
      );
    }
  }
  const renderHome = () => {

    if (clubInfo){

      return(

        <div style={{ display: 'flex', height: '100%' }}>

          <Container fluid className="align-items-center justify-content-center">

            <div style={{marginTop:10, display: 'grid', placeItems: 'center'}}>

              <h1>CLUB: {clubInfo.clubId}</h1>

              <Link to="/guide" className="link">VIEW SETUP GUIDE</Link>

            </div>

            <Form>

              <Form.Group className="mb-3">
                <Form.Label>CLUB NAME</Form.Label>
                <Form.Control type="text" placeholder="Club name" value={clubName} onChange={e => setClubName(e.target.value)} />

              </Form.Group>

              <Form.Group className="mb-3" >
                <Form.Label>WELCOME MESSAGE</Form.Label>
                <Form.Control type="text" placeholder="Welcome message" value={welcomeMessage} onChange={e => setWelcomeMessage(e.target.value)} />
              </Form.Group>

              <div style={{marginTop:10}}>

                <Form.Label>CLUB LOGO (300x80)</Form.Label>

                <div className="prow">

                  <div style={{backgroundColor:("#" + color), height:80, width:300, marginRight:10}}>

                    <img src={clubInfo.clubLogo} width="300" height="80" alt="Logo" />

                  </div>

                  <input id="clubLogoFile" type="file" className="hidden" accept='.jpg, .png' required onChange={ saveLogoFile } />

                </div>

              </div>

              <div style={{marginTop:10}}>

                <Form.Label>HEADER COLOR</Form.Label>

                <div>

                  <input
                    type="text" id="headerColor" name="headerColor" class="form-control"
                    value={color}
                    style={{width:100}}
                    onChange={e => setColor(e.target.value)}
                    required
                  />

                </div>

              </div>

              <div style={{marginTop:10}}>

                <Form.Label>APP BACKGROUND (1180x820)</Form.Label>

                <div style={{backgroundColor:'#000', width:295, height:205, marginRight:10}}>

                  <img src={clubInfo.backgroundImage} width="295" height="205" alt="Background" />

                </div>

                <input id="clubBackgroundFile" type="file" className="hidden" accept='.jpg, .png' required onChange={ saveBackgroundFile } />

              </div>

              <div style={{marginTop:10}} >

                {renderButton()}

              </div>

              <div style={{marginTop:10}} >

                {renderSaveAlert()}

              </div>

            </Form>

          </Container>

        </div>

      );
    }

    else {

      return(
        <PuttrSpinner />
      );

    }

  }
  return(

    <Container fluid className="align-items-center justify-content-center">

      <NavBar />

      <div style={{display: 'grid', placeItems: 'center'}}>

        {renderHome()}

      </div>

      <SideBar />

    </Container>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, null)(BrandingScreen);
