import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import axios from 'axios';
import moment from 'moment';
import NavBar from './navigation/NavBar';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { mkConfig, generateCsv, download } from "export-to-csv";

import { API_URL } from '../constants/Strings';
import { green } from '../constants/Colors';
import SideBar from './navigation/SideBar';
import PuttrSpinner from './common/PuttrSpinner';

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
  filename: "PUTTR_CLUB_EXPORT"
});

function UsersScreen(props){

  const [clubUsers, setClubUsers] = useState(null);

  useEffect(() => {

    getData();

  },[]);

  const getData = () => {

    if (props.app.clubId){
      let url = API_URL + '/club/sharedGames?clubId=' + props.app.clubId;

    	axios.get(url)
      .then(request => {
        setClubUsers(request.data);
      })
      .catch(function (error) {
        console.log("Fetch error:", error);
      });
    }
  }

  const exportCsv = () => {
    console.log("exporting");
    const csv = generateCsv(csvConfig)(clubUsers);
    download(csvConfig)(csv);
  }

  const renderUsers = () => {

    return clubUsers.map((item, index) => {

      const m = moment(item.created_at).fromNow();

      return(

        <tr key={index}>
          <td>{item.email}</td>
          <td><a href={item.gameUrl} className="link" target="_blank">VIEW GAME</a></td>
          <td>{m}</td>

        </tr>
      );

    });
  }

  const renderHome = () => {

    if (props.app.profile && clubUsers){

      return(

        <div style={{ display: 'flex', height: '100%' }}>

          <Container fluid className="align-items-center justify-content-center">

            <div style={{marginTop:20, display: 'grid', placeItems: 'center'}}>

              <h1>CLUB USERS</h1>

            </div>

            <div style={{marginTop:10, display: 'grid', placeItems: 'center'}}>

              <div style={{marginBottom:20}}>

                <Button variant="success" onClick={exportCsv}>EXPORT LIST</Button>

              </div>

              <div style={{maxWidth:600}}>

                <Table striped bordered hover>

                <thead>
                  <tr>

                    <th>EMAIL</th>
                    <th>GAME LINK</th>
                    <th>PLAYED</th>
                  </tr>
                </thead>
                <tbody>

                  {renderUsers()}

                </tbody>

                </Table>

              </div>

            </div>

          </Container>

        </div>

      );
    }

    else {
      return(
        <PuttrSpinner />
      );
    }

  }



  return(

    <Container fluid className="align-items-center justify-content-center">

      <NavBar />

      {renderHome()}

      <SideBar />

    </Container>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, null)(UsersScreen);
