import axios from 'axios';
import { API_URL } from '../constants/Strings';

export const setClubId = (cId) => dispatch => {
  dispatch({type: 'SET_CLUB_ID',payload: cId});
};

export const setUser = (user) => dispatch => {
  dispatch({type: 'SET_USER',payload: user});
};

export const getProfile = (token) => dispatch => {

  const url = API_URL + '/profile/';
  const opts = { headers: { Authorization: `Bearer ${token}`} };

	axios.get(url, opts)
  .then(request => {
    const p = request.data[0];
    dispatch({type: 'PROFILE_RECEIVED',payload: p});

    if (p.isClubAdmin){
      console.log("Setting club:", p.isClubAdmin);
      dispatch(setClubId(p.isClubAdmin));
    }

  })
  .catch(function (error) {
    console.log("Fetch profile error:", error);
  });
};

export const getClubInfo = (clubId) => dispatch => {

  const url = API_URL + '/club/info/' + clubId;

	axios.get(url)
  .then(request => {
    dispatch({type: 'CLUB_INFO_RECEIVED',payload: request.data[0]});
  })
  .catch(function (error) {
    console.log("Fetch profile error:", error);
  });
};

export const getClubStats = (clubId) => dispatch => {

  let url = API_URL + '/club/analytics/stats/' + clubId;

	axios.get(url)
  .then(request => {
    dispatch({type: 'CLUB_STATS_RECEIVED',payload: request.data});
  })
  .catch(function (error) {
    console.log("Fetch error:", error);
  });
};

export const getClubPutts = (clubId) => dispatch => {

  let url = API_URL + '/admin/club/putts/' + clubId;

	axios.get(url)
  .then(request => {
    dispatch({type: 'CLUB_PUTTS_RECEIVED',payload: request.data});
  })
  .catch(function (error) {
    console.log("Fetch error:", error);
  });
};
